// 活动专场
<template>
  <div class="serve content">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'Activity',
  components: {},
  data() {
    return {}
  },
}
</script>
